import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";



export default function AuthGuard(props) {
  const { children } = props;



  useEffect(() => {
   
  }, [window.localStorage.getItem("token")]);
  if (!window.localStorage.getItem("token")) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}
